.register-container {
  padding: 0 300px;
  min-height: calc(100vh - 64px - 106px);
  background: linear-gradient(to bottom, #F4FBFA 0%, #F4FBFA 5%, #FFFFFF 60%);
  padding-bottom: 106px;
  .new-submission-header {
    width: 100%;
		height: 150px;
		display: flex;
		align-items: center;
		background-image: url("../../assets/img/objects.png"); 
		background-size: 30% 100%;
		background-repeat: no-repeat;
		background-position: calc(100% - 30px) center; 
  }
}

.css-15xoa6u-MuiAutocomplete-endAdornment {
	top: 20px !important;
}

.main-container {
  padding-top: 74px;
}